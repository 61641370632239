.data-grid-ag-container .bf-form-hctable .header {background-color: #dfdfdf;}
.data-grid-ag-container .bf-form-hctable .header:not(:last-child) {border-color: #ffffff;}
.data-grid-ag-container .bf-form-hctable .no-rows-overlay {display:flex;justify-content:center;align-items:center;width:100%;height:100%;}
.data-grid-ag-container .bf-form-hctable .bf-form-hctable-cell.text {line-height:normal;padding:10px 17px;;white-space:pre-wrap}
.data-grid-ag-container .bf-form-hctable .bf-form-hctable-cell.ag-cell-wrap-text {line-height:normal;padding-top:4px;padding-bottom:4px;}
.data-grid-ag-container .bf-form-hctable .bf-form-hctable-btncell {padding-left:10px;padding-right:10px;}

.data-grid-ag-container .bf-form-hctable .bf-form-hctable-header.dark {background-color: #c0c0c0;}
.data-grid-ag-container .bf-form-hctable .bf-form-hctable-header.dark .ag-header-cell-resize::after {background-color:#c0c0c0 !important;}
.data-grid-ag-container .bf-form-hctable .bf-form-hctable-header.dark.ag-header-active {background-color: #c0c0c0 !important;}
.data-grid-ag-container .bf-form-hctable .bf-form-hctable-cell.dark {background-color: #fcfaf0;}

/*
.data-grid-ag-container .bf-form-hctable .dark.ag-header-active .ag-header-cell-resize::after {background-color:#fff}
 */