@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 100;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 300;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 400;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 500;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 700;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 900;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format('opentype');}

/* ToDo: remove important (css injection check) */
body, h1, h2, h3, h4, h5, h6, input, textarea, select {
  font-family: 'Noto Sans KR', sans-serif;
  /* font-family:'Spoqa Han Sans Neo','sans-serif'; */
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p,ul,ol,h1,h2,h3 { margin: 0;}
/* * { font-family: 'Spoqa Han Sans Neo', 'sans-serif'; } */
code {font-family: source-code-pro,Menlo,Monaco,Consolas,'Courier New',monospace;}

.bf-root { margin-left: 72px; margin-right: 72px;}

.bf-root .bf-header {height:70px;padding:0 60px;display:flex;flex-direction:row;align-items:center;box-shadow:none;
    justify-content: space-between;}
.bf-root .bf-header .btn-ul { color: #000; font-size: 0.9375rem; font-weight: 700; }
.bf-root .bf-header .btn-ul:hover {background-color:#f7f7f7}
.bf-root .bf-header .btn-ul.selected {background-color:#f7f7f7}
.bf-root .bf-header .btn-user { margin-left: 30px; }
.bf-root .bf-header .profile-info { font-size: 0.75rem; color:#a0a0a0; margin-left:6px; line-height:1.2}

.brianimpact-logo-small {width:110px;height:50px;background-position: 50%;background-size: contain; background-repeat: no-repeat;
  background-image: url(../static/brianimpact_logo.png)}

.footer {display:flex;height:70px;font-size:0.875rem;align-items:center;margin-top:60px;justify-content: center}
.bees-waggle-logo {margin-left:8px;width:48px;height:48px;background-position:50%;background-size:contain;background-repeat:no-repeat;background-image:url(../static/bees_waggle.png)}

.bat-main-container { margin-top: 70px;}

