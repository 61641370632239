.bat-center-container {
    position:absolute;top:0;right:0;bottom:0;left:0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    -moz-box-pack: center;
    -moz-box-align: center;
    -ms-box-pack: center;
    -ms-box-align: center;
    background: linear-gradient(to right, #F5CD1E, #FFA524);
}
.bat-center-container .header { padding: 18px 18px; background-color: #fff }
.bat-center-container .contents {display:flex;align-items: center;justify-content: center;height:80px;background-color:white;}
.bat-center-container .login-bottom {padding: 24px 18px 18px 18px;background-color:white;font-size:0.8125rem;text-align:center}

.bat-login {padding: 0 20px;width: 30rem;}
.bat-login .header {border-bottom: 2px solid black;}
.bat-login .logo {background: white url('../static/brianimpact_logo.png') no-repeat;height: 40px;background-size: auto 100%;}