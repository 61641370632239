.ag-theme-alpine {
    --ag-font-size: 0.875rem;
    --ag-font-family: 'Noto Sans KR', sans-serif;
    --ag-header-background-color: #dfdfdf;

    --ag-header-column-separator-display: block;
    --ag-header-column-separator-color: #ffffff;
    --ag-cell-horizontal-border: 1px solid #dfdfdf;
    --ag-border-color: #dfdfdf;
    --ag-grid-size: 5px;
}

.ag-center-cols-clipper {
    min-height: unset !important;
}

.data-grid-ag-container.no-min-height .ag-center-cols-viewport {
    min-height: unset !important;
}

.data-grid-ag-container .bf-form-hctable .ag-ltr .ag-header-viewport .ag-header-cell-resize::after {left:0}
.data-grid-ag-container .ag-header-row .ag-header-cell:last-child:after {display: none;}
/*
.data-grid-ag-container .ag-body .ag-cell:last-child {border-right: none}
.data-grid-ag-container .ag-row:last-child {border-bottom: none}
*/